.consolidation-kpis {
  &[data-type='Ventas'] {
    box-shadow: var(--card-shadow-sales);
  }
  &[data-type='Gastos'] {
    box-shadow: var(--card-shadow-expenses);
  }
  &[data-type='Resultado neto'] {
    box-shadow: var(--card-shadow-report-totalizer);
  }
}
