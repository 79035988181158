.projection-list-card {
  &[data-type='Ventas'] {
    box-shadow: var(--card-shadow-sales);
  }
  &[data-type='Gastos'] {
    box-shadow: var(--card-shadow-expenses);
  }
  &[data-type='consolidated'] {
    box-shadow: var(--card-shadow-default);
  }

  .projection-link-go-to-card {
    color: var(--absolute-blue-6);
    text-decoration: underline;
    &:hover {
      cursor: pointer;
      color: var(--absolute-blue-5);
    }
  }
}
