.integration-container-img {
  width: 250px;
  height: 90px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.ant-card-head {
  padding-left: 10px;
  padding-right: 10px;
}
