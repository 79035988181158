@import '../../../../../styles/common/mixinTableRowStyles';

.report-detail-container {
  .report-detail-table-actions-select {
    width: 200px;
    // margin-bottom: 24px;
  }
  .report-detail-space {
    display: flex;
    flex-wrap: wrap;
  }
  @include table-cell-styles;
  @include table-totalizer-row-styles;
  @include table-ratio-row-styles;
}
