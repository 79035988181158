@import '../../../../../../../styles/common/mixinTableRowStyles';

.real-control-table {
  @include table-totalizer-columns-styles;
  @include table-totalizer-row-styles;

  .hide-check {
    visibility: hidden;
  }

  .real-control-column-status {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    &[data-type='published'] {
      background-color: #98c4ed;
    }
    &[data-type='load'] {
      background-color: #fff27a;
    }
    &[data-type='approved'] {
      background-color: #64d199;
    }
  }
}
