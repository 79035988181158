.sider-layout {
  height: 80vh;
  position: 'fixed';
  left: 0;
  .sider-layout-content {
    overflow: auto;
    &.self-service-content {
      padding: 24px 24px;
    }
    padding: var(--sublayout-padding);
  }
  .sider-layout-menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: var(--inner-shadow-1-border-right);
    .sub-layout-actions {
      padding-top: 20px;
      .menu-icon {
        font-size: var(--font-size-x4);
        color: var(--font-black-secondary-text);
        &.selected {
          color: var(--absolute-blue-6);
        }
        &:hover {
          color: var(--absolute-blue-5);
        }
      }
    }
  }
}
