@import '../../../../../styles/common/mixinTableRowStyles';

.report-selectors {
  width: 200px;
  margin-bottom: 24px;
}
@include table-totalizer-row-styles;

.vertical-analisis-icon {
  padding-top: 3px;
}
