@import '../../../../../../../styles/common/mixinTableRowStyles';

.planning-rule-table {
  .column-import {
    color: var(--absolute-blue-8);
  }
  .ant-table-tbody .ant-table-wrapper .ant-table {
    @include table-totalizer-row-styles;
  }
}

.rule-table-alert {
  color: var(--absolute-blue-6);
  &:hover {
    cursor: pointer;
    color: var(--absolute-blue-5);
  }
}
