.projection-list-card {
  &[data-type='Ventas'] {
    box-shadow: var(--card-shadow-sales);
  }
  &[data-type='Gastos'] {
    box-shadow: var(--card-shadow-expenses);
  }
  &[data-type='Reporte'] {
    box-shadow: var(--card-shadow-report-totalizer);
  }
  &[data-type='economic'] {
    box-shadow: var(--card-shadow-economic-card);
  }
  &[data-type='financial'] {
    box-shadow: var(--card-shadow-financial-card);
  }
  &[data-type='Ingresos Operativos'] {
    box-shadow: var(--card-shadow-operating-income);
  }
  &[data-type='Egresos Operativos'] {
    box-shadow: var(--card-shadow-operating-expenses);
  }
  &[data-type='Ingresos Extraordinarios'] {
    box-shadow: var(--card-shadow-extraordinary-income);
  }
  &[data-type='Egresos Extraordinarios'] {
    box-shadow: var(--card-shadow-extraordinary-expenses);
  }
}
