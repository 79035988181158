@import '../../../../../styles/common/mixinTableRowStyles';

.financial-report-detail-container {
  .report-detail-table-actions-select {
    width: 200px;
  }
  .initial-situation-header {
    background: var(--neutral-2);
    padding: 12px 16px 12px 12px;
    display: flex;
    justify-content: space-between;
    border-right: 1px solid var(--neutral-5);
    border-top: 1px solid var(--neutral-5);
    border-left: 1px solid var(--neutral-5);
    color: var(--neutral-12);
  }
}

@include table-financial-row-styles;
@include table-cell-styles;
