.ant-carousel > .report-kpi-carousel {
  .slick-arrow.report-kpi-carousel-arrow {
    color: var(--font-black-primary-text);
    background-color: transparent;
    border-color: transparent;
    border-radius: 50%;
    font-size: var(--font-size-x4);
    &:hover,
    :focus {
      color: var(--absolute-blue-5);
    }
  }
  .slick-list {
    padding: 5px;
    .slick-track {
      .slick-slide {
        .report-detail-kpi-card {
          margin-right: 24px;
          box-shadow: var(--card-shadow-report-totalizer);
          &[data-type='grouper'] {
            box-shadow: var(--card-shadow-report-grouper);
          }
        }
      }
    }
  }
}
