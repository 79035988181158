.core-navigator {
  &[data-floated='true'] {
    font-size: var(--font-size-x2);
    position: fixed;
    z-index: 1;
    top: 16px;
  }
  &[data-size='big'] {
    /* Headline/H5 */
    font-family: var(--primary-font);
    font-style: normal;
    font-weight: var(--font-weight-medium);
    font-size: var(--font-size-x4);
    line-height: 32px;
    letter-spacing: 0.0025em;
  }
}
