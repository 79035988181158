input {
  &.cell-input-calculate-value {
    padding: 0px;
    border: px solid rgb(180, 180, 180);
    text-align: end;
    font-size: var(--font-size-sm);
  }
}
.cell-input-calculate-value {
  &.ant-input-affix-wrapper {
    padding: 0px;
    border: 1px solid gray;
    text-align: end;
    input:hover {
      cursor: pointer;
    }
  }
}
