.app-dashboard {
  padding: 30px 60px 0;
  .dashboard-card {
    min-height: 210px;
    text-align: start;
    background-position: right, top;
    background-repeat: no-repeat;
    background-size: 65% 100%;
    .dashboard-card-description {
      display: none;
    }
    &.planning {
      background-image: url('../../../../../../assets/images/compositions/planning-card.svg');
    }
    &.forecast {
      background-image: url('../../../../../../assets/images/compositions/forecast-card.svg');
    }
    &.reports {
      background-image: url('../../../../../../assets/images/compositions/analisis-card.svg');
    }
    &.control {
      background-image: url('../../../../../../assets/images/compositions/seguimiento-card.svg');
    }
    &:hover {
      background-position: right, bottom;
      background-size: 40% 50%;
      .dashboard-card-description {
        display: inline;
      }
    }
  }
  .dashboard-header {
    padding-bottom: 15px;
    color: var(--absolute-blue-6);
    font-size: var(--font-size-x3);
    .dashboard-period-navigator {
      cursor: pointer;
      font-family: var(--primary-font);
      font-weight: var(--font-weight-medium);
      font-size: var(--font-size-x4);
      line-height: 16px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
  }
}
