.cell-edit {
  border: 1px solid lightgray;
  padding-left: 10px;
  display: flex;
  align-items: center;
  height: 32px;
  &:hover {
    cursor: pointer;
  }
}
