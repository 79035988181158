@mixin table-row-styles {
  .bc-table-simple {
    .bc-table {
      .table-container,
      .ant-table-fixed {
        .body-wrapper {
          .body-row {
            @content;
          }
        }
      }
    }
  }
}

@mixin table-cell-styles {
  .green-cell {
    color: var(--go-green-6);
  }
  .red-cell {
    color: var(--sizziling-red-6);
  }
  .yellow-cell {
    color: var(--cyber-yellow-7);
  }
  .green-kpi {
    color: var(--go-green-3);
  }
  .font-width-ratio {
    font-weight: 600;
  }
  .font-width-financial {
    font-weight: 600;
    color: var(--absolute-blue-5);
  }
}

@mixin table-totalizer-row-styles {
  .totalizer-row {
    td {
      background-color: var(--neutral-5);
    }
  }
}

@mixin table-ratio-row-styles {
  .ratio-row {
    td {
      color: var(--ratio-violet);
      font-weight: 600;
    }
  }
}

@mixin table-applied-row-styles {
  .applied-var-row {
    .transaction-column {
      color: var(--absolute-blue-6);
    }
  }
  .applied-rule-row {
    .transaction-column {
      background-color: var(--absolute-blue-1);
    }
  }
}

@mixin table-totalizer-columns-styles {
  tbody tr td {
    &.totalizer-column {
      background-color: var(--neutral-5);
    }
  }
}

@mixin table-financial-row-styles {
  .financial-row {
    td {
      font-weight: 600;
      color: var(--absolute-blue-5);
      background-color: var(--neutral-4);
    }
  }
}
