// @import '../../../../../../../styles/common/mixinTableRowStyles';
@import '../../../../../../../../src/styles/common/mixinTableRowStyles';

.real-comparation-table {
  @include table-cell-styles;
}

.control-go {
  color: var(--absolute-blue-6);
  &:hover {
    cursor: pointer;
    color: var(--absolute-blue-5);
  }
}
