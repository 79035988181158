// Import fonts
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&family=DM+Sans:wght@400;500;700&display=swap');

:root {
  // Fonts
  --primary-font: 'DM Sans', sans-serif;
  --secondary-font: 'DM Mono', monospace;

  --font-size-base: 14px;
  --font-size-sm: calc(var(--font-size-base) - 2px);
  --font-size-x2: calc(var(--font-size-base) + 2px);
  --font-size-x3: calc(var(--font-size-x2) + 2px);
  --font-size-x4: calc(var(--font-size-x3) + 2px);
  --font-size-x5: calc(var(--font-size-x4) + 2px);

  --font-size-icon-base: 16px;
  --font-size-icon-sm: calc(var(--font-size-icon-base) - 8px);
  --font-size-icon-x2: calc(var(--font-size-icon-base) + 6px);

  --font-black-title: rgba(0, 0, 0, 0.85);
  --font-black-primary-text: rgba(0, 0, 0, 0.65);
  --font-black-secondary-text: rgba(0, 0, 0, 0.45);
  --font-black-disable: rgba(0, 0, 0, 0.25);

  $weights: (
    thin: 100,
    extra-light: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semi-bold: 600,
    bold: 700,
    extra-bold: 800,
    black: 900,
  );
  @each $name, $weight in $weights {
    --font-weight-#{$name}: #{$weight};
  }

  // App
  --app-layout-padding: 56px;
  --sublayout-padding: 24px 56px;

  // Neutral color
  --white: #ffffff;
  --neutral-2: #fafafa;
  --neutral-3: #f5f5f5;
  --neutral-4: #f0f0f0;
  --neutral-5: #d9d9d9;
  --neutral-6: #bfbfbf;
  --neutral-7: #8c8c8c;
  --neutral-8: #595959;
  --neutral-9: #434343;
  --neutral-10: #262626;
  --neutral-11: #1f1f1f;
  --neutral-12: #141414;
  --black: #000000;

  // Primary color
  --absolute-blue-1: #e1effa;
  --absolute-blue-2: #98c4ed;
  --absolute-blue-3: #6ca4e0;
  --absolute-blue-4: #4484d4;
  --absolute-blue-5: #2065c7;
  --absolute-blue-6: #0047ba;
  --absolute-blue-7: #003494;
  --absolute-blue-8: #00236e;
  --absolute-blue-9: #001447;
  --absolute-blue-10: #000821;

  // Secondary color
  --aero-blue-6: #5eb3e4;

  // Accents color
  --cyber-yellow-6: #ffcd00;
  --cyber-yellow-7: #d9a600;
  --sizziling-red-6: #fd4a5c;
  --baker-miller-pink-6: #e499b0;
  --go-green-6: #00aa63;
  --go-green-3: #64d199;
  --ratio-violet: #a73ba1;
  --financial-orange: #df9100;

  // Shadows - Inner
  --inner-shadow-1-border-up: inset 0px 1px 0px var(--neutral-5);
  --inner-shadow-1-border-right: inset -1px 0px 0px var(--neutral-5);
  --inner-shadow-1-border-down: inset 0px -1px 0px var(--neutral-5);
  --inner-shadow-1-border-left: inset 1px 0px 0px var(--neutral-5);
  --inner-shadow-1-border: var(--inner-shadow-1-border-left),
    var(--inner-shadow-1-border-right), var(--inner-shadow-1-border-up),
    var(--inner-shadow-1-border-down);

  --inner-shadow-2-border-up: inset 0px 3px 0px var(--absolute-blue-5);

  // Shadows - Drop
  --drop-shadow-1-up: 0px -2px 8px rgba(0, 0, 0, 0.15);
  --drop-shadow-1-right: 2px 0px 8px rgba(0, 0, 0, 0.15);
  --drop-shadow-1-down: 0px 2px 8px rgba(0, 0, 0, 0.15);
  --drop-shadow-1-left: -2px 0px 8px rgba(0, 0, 0, 0.15);
  --drop-shadow-2: 0px 4px 12px rgba(0, 0, 0, 0.09);

  --card-shadow-sales: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--go-green-6);
  --card-shadow-expenses: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--cyber-yellow-6);
  --card-shadow-report-grouper: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--sizziling-red-6);
  --card-shadow-report-totalizer: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--aero-blue-6);
  --card-shadow-default: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--absolute-blue-6);
  --card-shadow-reports-plika: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--absolute-blue-7);
  --card-shadow-reports-self-service: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--baker-miller-pink-6);
  --card-shadow-economic-card: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--cyber-yellow-6);
  --card-shadow-financial-card: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--aero-blue-6);
  --card-shadow-operating-income: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--go-green-6);
  --card-shadow-operating-expenses: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--cyber-yellow-6);
  --card-shadow-extraordinary-income: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--absolute-blue-6);
  --card-shadow-extraordinary-expenses: var(--drop-shadow-1-down),
    inset 0px 3px 0px var(--financial-orange);

  // Layout
  --layout-header-background-light: var(--white);
  --layout-header-background-dark: var(--black);
  --layout-header-height: 56px;
  --layout-header-padding: 16px;

  // Menu
  --menu-collapsed-width: 56px;
}

// Global Styles, not very nice
.ant-card {
  box-shadow: var(--drop-shadow-1-down);
}
.ant-card-actions > li > span {
  cursor: default;
}
.ant-btn-text:hover {
  color: #2065c7;
  border-color: #e1effa;
}
table thead tr th {
  font-family: var(--secondary-font);
  font-style: normal;
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-medium);
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--font-black-primary-text);
  &.column-collapser:hover {
    cursor: pointer;
  }
  .ant-table-column-sorter {
    margin-left: 2px;
  }
}
.ant-table-header {
  border-right: 1px solid var(--neutral-5);
}
.ant-table.ant-table-bordered .ant-table-container table .ant-table-thead tr th,
table .ant-table-thead tr th {
  border-bottom: 1px solid var(--neutral-5);
  border-top: 1px solid var(--neutral-5);
  &:first-child {
    border-left: 1px solid var(--neutral-5);
  }
  &:last-child {
    border-right: 1px solid var(--neutral-5);
  }
}

.ant-table-tbody > tr > td {
  & .transaction-cell-comment {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    display: block;
    border-left: 15px solid transparent;
    border-bottom: 15px solid transparent;
    pointer-events: none;
    &[data-visible='true'] {
      border-top: 15px solid var(--baker-miller-pink-6);
      pointer-events: auto;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}
