.dashboard-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.list-card-novedades {
  &[data-type='novedades'] {
    box-shadow: var(--card-shadow-default);
  }

  .container-description {
    display: none;
  }

  &:hover {
    justify-content: flex-start;
    cursor: pointer;
    .container-description {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      padding: 24px;
      min-height: 229px;
    }
    .container-imagen {
      display: none;
    }
  }
}
